import * as React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {classes} from './header.st.css'

export const Header = () => (
  <div className={classes.container} tabIndex={0}>
    <Text className={classes.title}>Select from list</Text>
    <br />
    <Text className={classes.subtitle}>Filter seats based on price and location</Text>
  </div>
)
