import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {FiltersRuntimeProps, FiltersOwnProps} from './interfaces'
import {Filters as Presentation} from './filters'

const mapRuntime = ({state, actions: {selectPrice, selectLocation}}: DetailsPageAppProps): FiltersRuntimeProps => ({
  plan: state.seating.plan,
  tickets: state.tickets,
  selectedPrice: state.seating.selectedPrice,
  selectedLocation: state.seating.selectedLocation,
  selectPrice,
  selectLocation,
  showAccessibilityMode: state.seating.showAccessibilityMode,
})

export const Filters = connect<FiltersOwnProps, FiltersRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
