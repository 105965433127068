import * as React from 'react'
import {Button} from 'wix-ui-tpa/Button'
import {getPreliminaryInvoice, getTaxConfig} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {SummaryContainer} from '../../summary-container'
import {InnerSummary} from '../../inner-summary'
import {SelectedTickets} from '../../../../../types'
import {classes} from './footer.st.css'
import {FooterProps} from '.'

export const Footer = ({selectedSeats, tickets, event, selectSeats, selectTickets, paidPlan}: FooterProps) => {
  const selectedTickets: SelectedTickets = selectedSeats.reduce((acc, seat) => {
    if (acc[seat.ticketId]) {
      acc[seat.ticketId].quantity = acc[seat.ticketId].quantity + 1
      acc[seat.ticketId].seatIds = [...acc[seat.ticketId].seatIds, seat.id]
    } else {
      acc[seat.ticketId] = {quantity: 1, seatIds: [seat.id]}
    }
    return acc
  }, {} as SelectedTickets)

  const handleAddTicketsToCart = () => {
    selectTickets(selectedTickets)
    selectSeats(selectedSeats)
  }

  return (
    <SummaryContainer>
      {selectedSeats.length ? (
        <InnerSummary
          invoice={getPreliminaryInvoice(tickets, selectedTickets, getTaxConfig(event), paidPlan)}
          selectedTickets={selectedSeats.length}
        />
      ) : null}
      <Button
        upgrade
        className={classes.button}
        disabled={!selectedSeats.length}
        onClick={handleAddTicketsToCart}
        data-hook={DH.ADD_TICKETS_TO_CART_BUTTON}
      >
        Add tickets to cart
      </Button>
      <div data-hook={DH.ACCESSIBILITY_MODE_END} tabIndex={0} />
    </SummaryContainer>
  )
}
