import * as React from 'react'
import ChevronUpSmall from 'wix-ui-icons-common/on-stage/ChevronUpSmall'
import ChevronDownSmall from 'wix-ui-icons-common/on-stage/ChevronDownSmall'
import classNames from 'classnames'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {getFormattedMoney, WIX_EVENTS_TICKET_DEFINITION_FQDN} from '@wix/wix-events-commons-statics'
import {Text} from 'wix-ui-tpa/Text'
import {classes} from './legend.st.css'
import {LegendItem} from './item'
import {LegendProps, LegendType} from '.'

export const Legend = ({plan, tickets}: LegendProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const [expanded, setExpanded] = React.useState(false)
  const [legend, setLegend] = React.useState<LegendType[]>([])

  React.useEffect(() => {
    const legendItems = plan.categories.map(({externalId, config: {color}}) => ({
      color,
      price: getFormattedMoney(
        tickets.find(ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, '')).price,
      ),
    }))

    setLegend(legendItems)
  }, [plan, tickets])

  return (
    <div
      className={classNames(classes.container, {
        [classes.desktopContainer]: !isMobile,
      })}
      onClick={() => setExpanded(!expanded)}
    >
      <Text className={classes.label}>
        {t('seatings_legend_title')} {expanded ? <ChevronDownSmall /> : <ChevronUpSmall />}
      </Text>
      <ul className={classNames(classes.list, {[classes.listExpanded]: expanded})}>
        {legend.map(({color, price}, index) => (
          <LegendItem key={index} color={color} label={price} />
        ))}
      </ul>
    </div>
  )
}
