import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {AccessibilityModeRuntimeProps, AccessibilityModeOwnProps} from './interfaces'
import {AccessibilityMode as Presentation} from './accessibility-mode'

const mapRuntime = ({
  state,
  actions: {setShowAccessibilityMode},
}: DetailsPageAppProps): AccessibilityModeRuntimeProps => ({
  showAccessibilityMode: state.seating.showAccessibilityMode,
  plan: state.seating.plan,
  tickets: state.tickets,
  selectedTickets: state.selectedTickets,
  event: state.event,
  selectedLocation: state.seating.selectedLocation,
  filteredCategories: state.seating.filteredCategories,
  setShowAccessibilityMode,
})

export const AccessibilityMode = connect<AccessibilityModeOwnProps, AccessibilityModeRuntimeProps>(mapRuntime)(
  Presentation,
)
export * from './interfaces'
