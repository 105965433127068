import * as React from 'react'
import {Dropdown} from 'wix-ui-tpa/Dropdown'
import {classes} from './styled-dropdown.st.css'
import {StyledDropdownProps} from '.'

export const StyledDropdown = ({placeholder, options, ariaLabel, onChange}: StyledDropdownProps) => (
  <Dropdown
    aria-label={ariaLabel}
    className={classes.dropdown}
    upgrade
    placeholder={placeholder}
    options={options}
    onChange={onChange}
  />
)
