import * as React from 'react'
import {DropdownOptionProps} from 'wix-ui-tpa/Dropdown'
import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import s from './filters.scss'
import {StyledDropdown} from './styled-dropdown'
import {FiltersProps} from '.'

export const Filters = ({
  plan,
  tickets,
  selectPrice,
  selectLocation,
  selectedLocation,
  selectedPrice,
  showAccessibilityMode,
}: FiltersProps) => {
  const defaultOption = {id: 'all', value: 'All', isSelectable: true}
  const prices: DropdownOptionProps[] = tickets.map(ticket => ({
    id: ticket.price.value,
    value: getFormattedMoney(ticket.price),
    isSelectable: true,
  }))
  const rows: DropdownOptionProps[] = plan.sectors
    .flatMap(sector => sector.elements)
    .filter(element => element.type === Type.ROW)
    .map(row => ({id: String(row.id), value: `Row ${row.title}`, isSelectable: true}))
  const selectedPriceValue = selectedPrice ? prices.find(price => price.id === selectedPrice).value : 'All'
  const selectedLocationValue = selectedLocation ? rows.find(row => row.id === selectedLocation).value : 'All'

  React.useEffect(() => {
    selectPrice(null)
    selectLocation(null)
  }, [showAccessibilityMode, selectPrice, selectLocation])

  return (
    <div className={s.container}>
      <StyledDropdown
        ariaLabel={`Select Price To Filter Seats. Currently Selected Price: ${selectedPriceValue}`}
        placeholder="Price: All"
        options={[defaultOption, ...prices]}
        onChange={selectedOption => selectPrice(selectedOption.id === 'all' ? null : selectedOption.id)}
      />
      <StyledDropdown
        ariaLabel={`Select Location To Filter Seats. Currently Selected Location: ${selectedLocationValue}`}
        placeholder="Location: All"
        options={[
          defaultOption,
          {id: '0', value: `Rows (${rows.length})`, isSectionTitle: true, isSelectable: false},
          ...rows,
        ]}
        onChange={selectedOption => selectLocation(selectedOption.id === 'all' ? null : selectedOption.id)}
      />
    </div>
  )
}
