import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {getBestPurchasedPlan, getSelectedPaidPlan} from '../../../../../selectors/paid-plans'
import {FooterRuntimeProps, FooterOwnProps} from './interfaces'
import {Footer as Presentation} from './footer'

const mapRuntime = ({state, actions: {selectTickets, selectSeats}}: DetailsPageAppProps): FooterRuntimeProps => ({
  event: state.event,
  tickets: state.tickets,
  paidPlan: getSelectedPaidPlan(state) || getBestPurchasedPlan(state),
  selectTickets,
  selectSeats,
})

export const Footer = connect<FooterOwnProps, FooterRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
